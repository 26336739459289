/**
 * Typography
 */

html {
  margin: 0 !important;
  padding: 0 !important;
  font-size: 10px;

  .modal-open {
    overflow: hidden !important;
  }
}

// box-sizing: border-box !important;
// margin: 0;
// padding: 0;
// max-width: 100%;
// overflow-x: hidden !important;

body {
  font-size: var(--font-size-b2);
  line-height: var(--line-height-b2);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: var(--font-primary);
  color: var(--color-body);
  font-weight: var(--p-regular);
}

.container-fluid {
  padding: 0 !important;
  max-width: 100% !important;
  overflow-x: hidden !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
address,
p,
pre,
blockquote,
menu,
ol,
ul,
table,
hr {
  margin: 0;
  margin-bottom: 15px;
}
.bg-color-black,
.bg-color-primary {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    color: var(--color-light);
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  word-break: break-word;
  font-family: var(--font-secondary);
  line-height: 1.4074;
  color: var(--color-heading);
}

h1,
.h1 {
  font-size: var(--h1);
  line-height: 1.19;
}

h2,
.h2 {
  font-size: var(--h2);
  line-height: 1.23;
}

h3,
.h3 {
  font-size: var(--h3);
  line-height: 1.14;
}

h4,
.h4 {
  font-size: var(--h4);
  line-height: 1.25;
}

h5,
.h5 {
  font-size: var(--h5);
  line-height: 1.24;
}

h6,
.h6 {
  font-size: var(--h6);
  line-height: 1.25;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  a {
    color: inherit;
  }
}

@media #{$md-layout} {
  h1,
  .h1 {
    font-size: var(--h1) - 4;
  }

  h2,
  .h2 {
    font-size: var(--h2) - 2;
  }

  h3,
  .h3 {
    font-size: var(--h3);
  }
}

@media #{$sm-layout} {
  h1,
  .h1 {
    font-size: var(--h1) - 6;
  }
  h2,
  .h2 {
    font-size: var(--h2) - 4;
  }
  h3,
  .h3 {
    font-size: var(--h3);
  }
}

@media #{$large-mobile} {
  h1,
  .h1 {
    font-size: var(--h1) - 8;
  }
}

h1,
.h1,
h2,
.h2,
h3,
.h3 {
  font-weight: var(--s-bold);
}

h4,
.h4,
h5,
.h5 {
  font-weight: var(--s-bold);
}

h6,
.h6 {
  font-weight: var(--s-medium);
}

p {
  font-size: var(--font-size-b2);
  line-height: var(--line-height-b2);
  font-weight: var(--p-regular);
  color: var(--color-body);
  margin: 0 0 24px;
  &:last-child {
    margin-bottom: 0 !important;
  }

  &.has-large-font-size {
    line-height: 1.5;
    font-size: 36px;
  }

  &.has-medium-font-size {
    font-size: 20px;
  }

  &.has-small-font-size {
    font-size: 13px;
  }

  &.has-very-light-gray-color {
    color: var(--color-white);
  }

  &.has-background {
    padding: 20px 30px;
  }
}
table {
  border-collapse: collapse;
  border-spacing: 0;
  margin: 0 0 20px;
  width: 100%;
}
table a,
table a:link,
table a:visited {
  text-decoration: none;
}

cite,
.wp-block-pullquote cite,
.wp-block-pullquote.is-style-solid-color blockquote cite,
.wp-block-quote cite {
  color: var(--heading-color);
}

/*---------------------------
	List Style 
---------------------------*/
ul,
ol {
  padding-left: 18px;
}

ul {
  list-style: disc;
  margin-bottom: 30px;
  padding-left: 20px;
  &.liststyle {
    &.bullet {
      li {
        font-size: 18px;
        line-height: 30px;
        position: relative;
        padding-left: 30px;
        @media #{$sm-layout} {
          padding-left: 19px;
        }
        &::before {
          position: absolute;
          content: "";
          width: 6px;
          height: 6px;
          border-radius: 100%;
          background: var(--color-body);
          left: 0;
          top: 10px;
        }

        & + li {
          margin-top: 8px;
        }
      }
    }
  }
  li {
    font-size: var(--font-size-b2);
    line-height: var(--line-height-b2);
    color: var(--color-body);
    margin-top: 10px;
    margin-bottom: 10px;

    a {
      text-decoration: none;
      color: var(--color-heading);
      @extend %transition;

      &:hover {
        color: var(--color-primary);
      }
    }
  }
  ul {
    margin-bottom: 0;
  }
}

ol {
  margin-bottom: 30px;
  li {
    font-size: var(--font-size-b1);
    line-height: var(--line-height-b1);
    color: var(--color-body);
    margin-top: 10px;
    margin-bottom: 10px;

    a {
      color: var(--heading-color);
      @extend %transition;
      text-decoration: none;

      &:hover {
        color: var(--color-primary);
      }
    }
  }
  ul {
    padding-left: 30px;
  }
}
