/*----------------------
    NavBar Styles  
------------------------*/

.loading-icon {
  background-color: #0c1722;
  color: #fff;
  width: 100vw;
  height: 100vh;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
}

ul {
  &.mainmenuwrapper {
    padding: 0;
    list-style: none;
    display: flex;
    margin: 0;
    li {
      margin-right: 30px;
      margin-top: 0;
      margin-bottom: 0;
      &:last-child {
        margin-right: 0;
      }

      a,
      button {
        font-weight: 600;
        font-size: 15px;
        line-height: 2.1;
        position: relative;
        &::after {
          position: absolute;
          content: "";
          width: 0;
          height: 3px;
          background: #000;
          left: 0;
          bottom: 0;
          transition: 0.4s;
        }
      }
      &.is-current {
        a {
          &.menu-hover-link {
            &::after {
              width: 100%;
            }
            span {
              &::before {
                color: var(--color-secondary);
              }
            }
          }
        }
      }
    }
  }
}
