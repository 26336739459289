/*--------------------
Portfolio Styles 
----------------------*/

.portfolio {
  margin-top: 60px;
  position: relative;

  .carousel-item {
    position: relative;
  }

  .carousel-item .btn {
    position: absolute;
    top: 0;
    right: 0;
    background-color: transparent;
    color: white;
    font-size: 22px;
    border: none;
    cursor: pointer;
  }

  .carousel-control-prev,
  .carousel-control-next {
    height: fit-content;
    top: 50%;
  }

  @media #{$lg-layout} {
    margin-top: 50px;
  }

  @media #{$md-layout} {
    margin-top: 50px;
  }

  @media #{$sm-layout} {
    margin-top: 50px;
  }

  .modal-title {
    color: #0c1722 !important;
  }

  .modal-content {
    padding: 20px;
  }

  .modal-body p {
    text-align: justify;
    width: 100%;
    color: #0c1722;
    margin-bottom: 40px;
  }

  .modal {
    img,
    .image-btn {
      width: 100%;
      object-fit: cover;
      object-position: 50% 50%;
    }

    iframe {
      width: 100%;
      height: 65vh;
    }
  }

  textarea:focus {
    outline: none;
  }

  .thumbnail {
    .modal-btn,
    .modal-btn:focus,
    .modal-btn:hover,
    .modal-btn:active {
      background-color: transparent;
      border: none;
      box-shadow: none;
      outline: 0;
    }

    .outside-img {
      height: 400px;
      object-fit: cover;
    }

    #parent {
      width: 100%;
      height: 400px;
      border: solid 1px #000;
    }

    iframe {
      width: 100%;
      height: 400px;
      border: 0px;
    }

    #parent > button {
      opacity: 0.3;
      position: relative;
      float: right;
      width: 100%;
      height: 400px;
      margin-top: -400px;
      transition: 0.5s;
    }

    #parent > button {
      opacity: 1;
    }
  }

  .content {
    padding: 0.375rem 0.75rem;
    margin-top: 20px;

    h4 {
      margin-bottom: 0;
    }

    p {
      margin-top: 0;
    }
  }
}
