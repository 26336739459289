/* ----------------------
Contact Styles  
------------------------*/
.contact-info-list-wrapper {
  p {
    text-align: justify;
  }

  .list {
    display: flex;
    .icon {
      color: var(--color-primary);
      display: block;
      width: 17px;
      height: 17px;
      margin-right: 10px;
    }
    .label {
      min-width: 85px;
      display: inline-block;
      margin-right: 10px;
      font-weight: 500;
      color: var(--color-heading);
    }
    .link {
      position: relative;
      font-weight: 400;
      color: var(--color-primary);
      border-bottom: 1px solid transparent;
      transition: all 0.3s ease;
      &:hover {
        border-bottom-color: var(--color-primary);
      }
    }
    & + .list {
      margin-top: 10px;
    }
  }
}

.form-group {
  position: relative;
  margin-bottom: 30px;
  label {
    font-size: 13px;
    line-height: 1.714;
    position: absolute;
    top: 13px;
    background: rgb(255, 255, 255);
    padding: 0px 5px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    color: #333;
    font-weight: 400;
  }

  input,
  textarea {
    height: 44px;
    padding-left: 10px;
    padding-right: 10px;
    transition: 0.3s;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.2);
    color: #dba14d;
    border-radius: 10px;
    font-size: 14px;
    width: 100%;
    font-weight: 400;
    &:focus {
      border: 1px solid var(--color-primary) !important;
      outline: none;
    }
  }

  textarea {
    min-height: 120px;
    transition: 0.3s;
    resize: none;
    &:focus {
      border: 1px solid var(--color-primary) !important;
      outline: none;
    }
  }

  .error {
    color: #f10;
    display: inline-block;
    left: 0px;
    bottom: -26px;
    font-size: 12px;
  }
}

.form-group input:focus ~ label,
.form-group.has-value label {
  top: -27px;
}

.form-group textarea:focus ~ label {
  top: -27px;
}

.form-output {
  margin-top: 10px;
  font-size: 14px;
  line-height: 1.714;
  &.success {
    color: rgb(40, 167, 69) !important;
  }
  &.error {
    color: #f10;
  }
}

/*--------------------------
    Contact Area Styles  
----------------------------*/

.rn-contact-area {
  .info {
    padding-bottom: 45px;
    p {
      font-size: 15px;
      line-height: 2.1;
      font-weight: 400;
      margin-bottom: 45px;
      text-align: justify;
    }
  }
}
.contact-info-list-wrapper {
  div {
    border-radius: 10px;
  }
}

.form-submit {
  button {
    &.rn-button {
      width: 100%;
    }
  }
}
