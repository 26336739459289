/*=====================
All Extend Here
=======================*/

//===== Ncc Colors =====//
%color-primary {
    color: var(--color-primary)
}

%color-secondary {
    color: var(--color-secondary)
}

%color-tertiary {
    color: var(--color-tertiary)
}

.color-primary {
    color: var(--color-primary);
}

.secondary-color {
    color: var(--color-secondary);
}

.tertiary-color {
    color: var(--color-tertiary);
}

//===== BG Ncc Colors =====//
%bg-primary-color {
    background: var(--color-primary);
}

%bg-secondary-color {
    background: var(--color-secondary)
}

%bg-tertiary-color {
    background: var(--color-tertiary)
}



// Others 

%box-shadow {
    box-shadow: var(--shadow-primary);
}

%radius {
    border-radius: var(--radius);
}

.radius {
    @extend %radius;
}


/*=============== Style Css =============*/

%liststyle {
    padding: 0;
    margin: 0;
    list-style: none;
}
.liststyle {
    padding: 0;
    margin: 0;
    list-style: none;
}

%transition {
    transition: var(--transition);
}

%bgImagePosition {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.bgImagePosition {
    @extend %bgImagePosition;
}
.theme-color {
    color: var(--color-primary);
}



