/*----------------------
Clients Styles  
------------------------*/
#clients {
  .wrapper {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
  }

  img {
    width: 100%;
    height: 75px;
    object-fit: contain;
    cursor: auto;

    @media #{$sm-layout} {
      height: 85px !important;
    }
  }
}
