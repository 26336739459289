/*-----------------
 * Button
-------------------*/
a,
button {
  &.rn-button {
    text-decoration: none;
    background-color: var(--color-primary);
    display: inline-block;
    padding: 0px 50px;
    overflow: hidden;
    position: relative;
    z-index: 1;
    outline: none;
    border: 2px solid #000;
    height: 60px;
    line-height: 57px;
    border-radius: 500px;
    color: #fff;
    font-size: 15px;
    @extend %transition;
    span {
      position: relative;
      z-index: 2;
    }

    &.btn-white {
      background-color: var(--color-white);
      color: var(--color-extra03);
      span {
        color: var(--color-extra03);
      }
      &:hover {
        color: var(--color-extra04);
        span {
          color: var(--color-extra04);
        }
      }
    }
    &:hover {
      color: var(--color-primary);
      background-color: transparent;
    }
  }
}

/*-----------------
    Link Button  
-------------------*/
a {
  &.link-button {
    padding: 0;
    padding-top: 9px;
    padding-bottom: 9px;
    display: inline-block;
    @extend %transition;
    span {
      position: relative;
      display: inline-block;
      &::after {
        background: var(--color-extra02);
        position: absolute;
        content: "";
        left: 0;
        bottom: 0;
        height: 1px;
        width: 100%;
        @extend %transition;
      }
    }
    &:hover {
      span {
        &::after {
          background: var(--color-primary);
        }
      }
    }
  }
}

/* Button Group  */
.button-group {
  margin: 0 -25px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  a,
  button {
    margin: 0 25px;
    display: inline-block;
  }
}

/*----------------------
    Read More Button  
-----------------------*/
a {
  &.readmore-btn {
    border-bottom: 1px solid transparent;
    transition: all 0.3s ease;
    font-size: 16px;
    color: var(--color-primary);
    &:hover {
      border-bottom-color: var(--color-primary);
    }
  }
}
