/*===========================
    Start Footer Area 
=============================*/
.rn-footer-area {
  p,
  h3 {
    margin: 0;
  }

  h3 {
    font-size: var(--h3);
  }

  a {
    color: #fff;
  }

  .ft-info {
    p {
      display: block;
      color: #fff;
      font-weight: 500;
      font-size: 15px;
      line-height: 2.1;
    }
  }
}
.rn-footer-area {
  position: relative;
  .copyright-text {
    color: #fff;
  }

  .social-share {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
    li {
      margin-right: 20px;
      a {
        display: inline-block;
        svg {
          width: 20px;
          height: 20px;
          fill: #fff;
          transition: 0.5s;
        }
      }
      &:hover {
        a {
          svg {
            opacity: 0.5;
          }
        }
      }
    }
  }
}

.back-to-top {
  position: fixed;
  bottom: 100px;
  @media #{$md-layout} {
    right: 30px;
    bottom: 50px;
  }
  @media #{$sm-layout} {
    right: 30px;
    bottom: 50px;
  }
}
