.home-wrapper {
  position: relative;
  text-align: center;
  color: white;

  .card-title {
    white-space: nowrap;
  }

  p,
  h3 {
    cursor: auto;
  }

  a.rn-button {
    white-space: nowrap;
    @media #{$sm-layout} {
      height: auto;
      line-height: normal;
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.home-image {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  opacity: 0.3;
  cursor: auto;
}

ul button {
  fill: #dba14d !important;
}

button {
  background-color: transparent;
  border: none;
  color: #dba14d;
}
