/*----------------------
About Styles  
-----------------------*/

.about-style {
  .content {
    .title-wrap {
      border-bottom: 1px solid #fff;
      padding-bottom: 25px;
      margin-bottom: 25px;
      @media #{$md-layout} {
        padding-bottom: 6px;
        margin-bottom: 22px;
      }
      @media #{$sm-layout} {
        padding-bottom: 6px;
        margin-bottom: 22px;
      }
    }

    textarea {
      background: none;
      resize: none;
      border: none;
      overflow: hidden;
      width: 100%;
      height: 100%;
    }

    textarea:focus {
      outline: none;
    }

    .title {
      text-transform: uppercase;
      font-weight: 900;
      font-size: 35px;
      position: relative;
      color: #000;
      line-height: 1.4;
      @media #{$sm-layout} {
        font-size: 26px;
      }
      span {
        display: relative;
        padding-top: 10px;
        &.bg {
          position: absolute;
          top: 0;
          color: transparent;
          font-size: 150px;
          font-weight: 900;
          opacity: 0.1;
          line-height: 0;
          -webkit-text-stroke: 1px #fff;
          user-select: none;
          -webkit-user-select: none;
          @media #{$lg-layout} {
            font-size: 90px;
          }
          @media #{$md-layout} {
            font-size: 70px;
          }
          @media #{$sm-layout} {
            font-size: 70px;
          }
        }
      }
    }

    .subtitle {
      font-weight: 600;
      font-size: 15px;
      line-height: 2.1;
      color: #333;
    }

    .description {
      margin-bottom: 12px;
      font-size: 15px;
      line-height: 2.1;
      font-weight: 400;
      color: #333;
      text-align: -webkit-auto;
      @media #{$sm-layout} {
        padding-right: 0;
      }
    }

    ul {
      &.aboutdata-list {
        margin-top: 36px;
      }
    }
  }
  .thumbnail {
    @media #{$md-layout} {
      margin-bottom: 40px;
    }
    @media #{$sm-layout} {
      margin-bottom: 40px;
    }
    .about-images {
      @media #{$lg-layout} {
        width: 100% !important;
      }
      @media #{$md-layout} {
        width: 100% !important;
      }
      @media #{$sm-layout} {
        width: 100% !important;
      }
      img {
        object-fit: cover;
        border-radius: 5px;
      }
    }
  }
}
