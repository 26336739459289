/*-----------------------
    Header Scss
------------------------*/

.rn-header {
  &.header-with-sidebar {
    width: 400px;
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    background-color: #fff;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.09);
    z-index: 100;
    transition: all 0.3s ease;
    .header-inner {
      width: 100%;
      height: 100%;
      clear: both;
      float: left;

      .logo {
        position: relative;
        z-index: 10;
        padding: 60px;
      }
      .mainmenu-inner {
        padding: 0px 60px 0px 60px;
        .mainmenu {
          margin: 0;
          list-style-type: none;
          padding: 0;
          display: block;
          a,
          button {
            &.nav-link {
              text-decoration: none;
              color: #000;
              text-transform: capitalize;
              display: block;
              position: relative;
              padding: 13px 0px;
              transition: all 0.3s ease;
              font-size: 16px;
              font-weight: 500;
              position: relative;
              &::before {
                position: absolute;
                content: "";
                width: 100%;
                height: 2px;
                background-color: rgba(0, 0, 0, 0.1);
                left: 0px;
                bottom: 0px;
              }
              &::after {
                position: absolute;
                content: "";
                width: 0%;
                height: 2px;
                background-color: rgba(0, 0, 0, 0.08);
                left: 0px;
                bottom: 0px;
                transition: all 0.3s ease;
              }
              &:last-child {
                &::before {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }

  &.header-transparent {
    left: 0;
    right: 0;
    width: auto;
    z-index: 99;
    position: fixed;
  }
}

.navbar {
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.header-default {
  .header-inner {
    display: flex;
    justify-content: space-between;
    padding: 30px 0;
    align-items: center;

    @media #{$lg-layout} {
      padding: 30px;
    }
    @media #{$md-layout} {
      padding: 30px;
    }
    @media #{$sm-layout} {
      padding: 30px;
    }
  }
  &.scrolled {
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.06);
    .header-inner {
      padding: 15px 0;
      @media #{$lg-layout} {
        padding: 15px;
      }
      @media #{$md-layout} {
        padding: 15px;
      }
      @media #{$sm-layout} {
        padding: 15px;
      }
    }
  }

  .header-left {
    display: flex;
    align-items: center;
    margin-bottom: -10px;
  }

  .header-search {
    button {
      background: transparent;
      padding: 0;
      border: 0 none;
      color: #fff;
      font-size: 24px;
      font-weight: 400;
      outline: none;
    }
  }
}

/*------------------------
    Hambergur Menu
-------------------------*/

.hambergur-menu {
  cursor: pointer;
  .hamburger-box {
    width: 30px;
    height: 16px;
    display: inline-block;
    position: relative;
    cursor: pointer;

    .hamburger-inner,
    .hamburger-inner::after,
    .hamburger-inner::before {
      background-color: #000;
      width: 30px;
      top: auto;
      bottom: 0;
      transition-duration: 0.13s;
      transition-delay: 0.13s;
      transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
      height: 2px;
      position: absolute;
      transition-property: transform;
      border-radius: 4px;
    }
    .hamburger-inner {
      &::before,
      &::after {
        content: "";
      }
      &::before {
        transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
          transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
        top: -7px;
      }
      &::after {
        top: -14px;
        transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1),
          opacity 0.1s linear;
        bottom: -7px;
      }
    }
  }

  &.hambergur-menu-open {
    .hamburger-box {
      .hamburger-inner {
        transform: translate3d(0, -8px, 0) rotate(45deg);
        transition-delay: 0.22s;
        transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      }
      .hamburger-inner::before {
        top: 0;
        transform: rotate(90deg);
        transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
          transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
      }
      .hamburger-inner::after {
        top: 0;
        opacity: 0;
        transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333),
          opacity 0.1s 0.22s linear;
      }
    }
  }
}
