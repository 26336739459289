/*-- Variables --*/
:root {
  //Themes colors
  --color-primary: #dba14d;
  --color-secondary: #050505;
  --color-tertiary: #050505;

  // Typo Color
  --color-heading: #000000;
  --color-body: #fff;

  // Gery Colors
  --color-gray: #65676b;
  --color-midgray: #9f9f9f;
  --color-light: #eee;
  --color-lighter: #ced0d4;
  --color-lightest: #f0f2f5;

  --color-border: #e6e6e6;
  --color-white: #ffffff;

  // Notify Colors
  --color-success: #3eb75e;
  --color-danger: #ff0003;
  --color-warning: #ff8f3c;
  --color-info: #1ba2db;

  //Social icon colors
  --color-facebook: #3b5997;
  --color-twitter: #1ba1f2;
  --color-youtube: #ed4141;
  --color-linkedin: #0077b5;
  --color-pinterest: #e60022;
  --color-instagram: #c231a1;
  --color-vimeo: #00adef;
  --color-twitch: #6441a3;

  // Extra Color
  --color-extra01: #ffedeb;
  --color-extra02: #ffffff;
  --color-extra03: #041230;
  --color-extra04: #f1f1f1;
  --color-extra05: #14131b;

  //Border-radius
  --radius: 3px;
  --radius-big: 16px;
  --radius-small: 2px;
  --border-width: 2px;

  //Font weight
  //primary font
  --p-light: 300;
  --p-regular: 400;
  --p-medium: 500;
  --p-semi-bold: 600;
  --p-bold: 700;
  --p-extra-bold: 800;
  --p-black: 900;

  //secondary font
  --s-light: 300;
  --s-regular: 400;
  --s-medium: 500;
  --s-bold: 700;
  --s-black: 900;

  //Shadows
  --shadow-light: 0 2px 6px 0 rgba(0, 0, 0, 0.05);
  --shadow-dark: 0 2px 6px 0 rgba(0, 0, 0, 0.2);

  //transition easing
  --transition: 0.3s;

  //Font Family
  --font-primary: "Open Sans", sans-serif;
  --font-secondary: "Open Sans", sans-serif;

  //Fonts Size
  --font-size-b1: 18px;
  --font-size-b2: 15px;
  --font-size-b3: 14px;
  --font-size-b4: 12px;

  //Line Height
  --line-height-b1: 1.6; //28px
  --line-height-b2: 2.1; //24px
  --line-height-b3: 1.6; //22px
  --line-height-b4: 1.3; //16px

  // Heading Font
  --h1: 44px; //52px 1.29
  --h2: 36px; //44px 1.23
  --h3: 30px; // 34px 1.14
  --h4: 24px; //30px  1.25
  --h5: 18px; //22px 1.24
  --h6: 16px; //20px 1.25
}

// Layouts Variation
$smlg-device: "only screen and (max-width: 1199px)";
$extra-device: "only screen and (min-width: 1600px) and (max-width: 1919px)";
$laptop-device: "only screen and (min-width: 1200px) and (max-width: 1599px)";
$lg-layout: "only screen and (min-width: 992px) and (max-width: 1199px)";
$md-layout: "only screen and (min-width: 768px) and (max-width: 991px)";
$sm-layout: "only screen and (max-width: 767px)";
$large-mobile: "only screen and (max-width: 575px)";
$small-mobile: "only screen and (max-width: 479px)";
